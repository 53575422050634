<template>
    <div id="contenedor">
        <div id="borde">

            <router-view :key="$route.path" />

        </div>
    </div>
</template>

<style scoped>
#contenedor {
    width: 100%;
    height: 100vh;
    /*background-image: url("../assets/patron_skyline.svg"), url("../assets/patron_home.svg");*/
    background-image: url("../assets/patron_juego.svg");
    background-color: #0C1D3E;
    background-size: cover;
    padding: 18px 20px;
}
#borde {
    border-radius: 25px;
    border: 2px solid white;
    margin: 0 auto;

    max-width: 1280px;
    height: 100%;

    position: relative;
}
</style>
